import React, { useEffect, useCallback } from 'react';
import './Animations.css';
import './App.css';
import './Screen.css';
import ErrorBoundary from './components/ErrorBoundary';
import Header from './components/Header';
import Content from './components/Content';
import Footer from './components/Footer';
import ConnectOptions from './components/ConnectOptions';
import { useStateValue } from './context/StateProvider';
import BrokenComponent from './components/BrokenComponent';
import Notification from './components/Notification';
import Loader from './components/Loader';
import Modal from './components/Modal';
import Disclaimer from './components/Disclaimer';

function App() {
    const [{}, dispatch] = useStateValue()
    const handleResize = useCallback(() => {
        dispatch({
            type: 'SET_DIMENSIONS'
        })
    }, [dispatch])

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [handleResize])
    return (
        <ErrorBoundary fallback={<BrokenComponent />}>
            <Notification />
            <Header />
            <Content />
            {/* <Disclaimer /> */}
            <Footer />
            <ConnectOptions />
            <Loader />
            <Modal />
        </ErrorBoundary>
    );
}

export default App;
