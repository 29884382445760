const { BrowserProvider, getDefaultProvider, Contract } = require('ethers');

export function connectWallet() {
    return new Promise(async (resolve, reject) => {
        if (typeof window.ethereum !== 'undefined') {
            try {
                const provider = new BrowserProvider(window.ethereum)
                const signer = await provider.getSigner()
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                const accounts = await provider.listAccounts();
                return resolve({provider, signer, address: accounts[0].address})
            } catch (error) {
                if (
                    error.name === 'UserRejectedRequestError' ||
                    error.code === 4001 ||
                    error.message === 'User rejected the request.'
                ) return reject(error)
                try {
                    const provider = getDefaultProvider();
                    const signer = provider.getSigner()
                    await window.ethereum.request({ method: 'eth_requestAccounts' });
                    const accounts = await provider.listAccounts();
                    return resolve({provider, signer, address: accounts[0].address})
                } catch (err) {
                    return reject(err)
                }
            }
        } else {
            return reject({ message: 'MetaMask not detected. Please install MetaMask to use this feature.' })
        }
    })
}
export function initContract(address, abi, provider = null) {
    return new Promise(async (resolve, reject) => {
        try {
            if (provider === null) {
                provider = getDefaultProvider()
                provider = provider.getSigner()
            }
            const contract = new Contract(address, abi, provider)
            return resolve(contract)
        } catch(err) {
            return reject(err)
        }
    })
}