import React, { useState, useEffect } from 'react'
import { useStateValue } from '../context/StateProvider';
import CheckEligibility from './CheckEligibility';

const Content = () => {
    const [{ content }] = useStateValue();
    const [contentDisplay, setContentDisplay] = useState(<CheckEligibility />);

    useEffect(() => {
        if (content != null)
            setContentDisplay(content)
    }, [content])
    return (
        <div className='content-container'>
            {contentDisplay}
        </div>
    )
}

export default Content
