import React, { useState, useEffect } from 'react'
import WalletManager from './WalletManager'
import { GrHelpBook } from 'react-icons/gr';
import { useStateValue } from '../context/StateProvider';
import ConvertAirToken from './ConvertAirToken';
import Guide from './Guide';
import CheckEligibility from './CheckEligibility';
import ClaimAirdrop from './ClaimAirdrop';

import { CiMenuKebab } from "react-icons/ci";
import { GrClose } from "react-icons/gr";
import logo from '../media/images/logo-main.png'


const Header = () => {
    const [{ content, wallet, windowDimensions, showWalletOptions }, dispatch] = useStateValue();
    const [navClass, setNavClass] = useState('')
    const [showMenu, setShowMenu] = useState(true)

    useEffect(() => {
        if (windowDimensions.width > 900) {
            setNavClass('desktop')
            setShowMenu(true)
        }
        else {
            setNavClass('mobile')
            setShowMenu(false)
        }
    }, [windowDimensions])
    useEffect(() => {
        setShowMenu(false)
    }, [content, showWalletOptions, wallet])
    return (
        <header>
            <nav className={navClass}>
                <div className="logo-bearer">
                    <div className='logo-container'>
                        <img src={logo} alt='LOGO' onClick={() => dispatch({ type: 'TOGGLE_CONTENT', content: <CheckEligibility /> })} />
                    </div>
                </div>
                {
                    navClass === 'desktop' || showMenu === true ? (
                        <div className='menu'>
                            {
                                navClass === 'mobile' ? (
                                    <div className="mobile-menu-control close">
                                        <GrClose onClick={() => setShowMenu(false)} />
                                    </div>
                                ) : null
                            }
                            <ul>
                                <li
                                    className={content === <CheckEligibility /> || content === <ClaimAirdrop /> ? 'active' : null}
                                    onClick={() => dispatch({ type: 'TOGGLE_CONTENT', content: <CheckEligibility /> })}
                                >Airdrop</li>
                                <li
                                    className={content === <ConvertAirToken /> ? 'active' : null}
                                    onClick={() => dispatch({ type: 'TOGGLE_CONTENT', content: <ConvertAirToken /> })}
                                >Convert AirToken</li>
                                <li
                                    className={content === <Guide /> ? 'active' : null}
                                    onClick={() => dispatch({ type: 'TOGGLE_CONTENT', content: <Guide /> })}
                                >Guide <GrHelpBook /></li>
                            </ul>
                            <div className='wallet'>
                                <WalletManager />
                            </div>
                        </div>
                    ) : (
                        <div className="mobile-menu-control open">
                            <CiMenuKebab onClick={() => setShowMenu(true)} />
                        </div>
                    )
                }
            </nav>
        </header>
    )
}

export default Header
