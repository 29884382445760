import React from 'react'

import powered from '../media/images/poweredby.svg'
import { FaXTwitter } from "react-icons/fa6";
import { LiaDiscord } from "react-icons/lia";
import { TbBrandTelegram } from "react-icons/tb";


const Footer = () => {
    return (
        <footer>
            <nav>
                <ul>
                    <li><a href="" target="_blank"><FaXTwitter /></a></li>
                    <li><a href="" target="_blank"><LiaDiscord /></a></li>
                    <li><a href="" target="_blank"><TbBrandTelegram /></a></li>
                </ul>
                <img src={powered} alt="" />
            </nav>
        </footer>
    )
}

export default Footer
