import React, { useEffect, useState } from 'react'
import { useStateValue } from '../context/StateProvider'
import { GrClose } from "react-icons/gr";

const Notification = () => {
    const [{ showFlashNotification }, dispatch] = useStateValue()
    const [isPaused, setIsPaused] = useState(false)

    const handleMouseOver = () => {
        setIsPaused(true);
      };
    
    const handleMouseOut = () => {
        setIsPaused(false);
    };

    useEffect(() => {
        let timeoutInterval
        if (showFlashNotification !== null) {
            let count = showFlashNotification.timeToLive || 5
            timeoutInterval = setInterval(() => {
                if (!isPaused) {
                    if (count <= 0) {
                        dispatch({
                            type: 'CLEAR_NOTIFICATION'
                        })
                        clearTimeout(timeoutInterval)
                    }
                    count--
                }
            }, 1000)
        }

        return (() => {
            if (timeoutInterval != null) {
                clearTimeout(timeoutInterval)
            }
        })
    }, [showFlashNotification, isPaused])
    return (
        showFlashNotification != null ? (
            <div className='notification'>
                <div 
                    className={`message ${showFlashNotification.type}`}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}>
                    <GrClose onClick={() => dispatch({type: 'CLEAR_NOTIFICATION'})} />
                    {showFlashNotification.message}
                </div>
            </div>
        ) : null
    )
}

export default Notification
