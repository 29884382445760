import React from 'react'
import { useStateValue } from '../context/StateProvider'

const Guide = () => {
    const [{ tokenName, networkSymbol, airTokenName, promoTitle, minTokenBalanceRequired }] = useStateValue()

    return (
        <div className='content'>
            <div className="content-container guide">
                <h1>A Guide To Claim This Airdrop</h1>
                <div className="row">
                    <h3>Prerequisites</h3>
                    <article>
                        <ol>
                            <li>A crypto wallet that contains,</li>
                            <li>{networkSymbol} coin (worth at least $1 for signing fee) and,</li>
                            <li>A minimum amount of {minTokenBalanceRequired} {tokenName}</li>
                        </ol>
                    </article>
                </div>
                <div className="row">
                    <article>
                        <h3>How To Join {promoTitle} {tokenName} Airdrop</h3>
                        <p>Follow the steps below carefully to join the airdrop.</p>
                    </article>
                    <article>
                        <h3>Connect Your Wallet</h3>
                        <p>
                            After making sure you have satisfied the above prerequisites, connect your wallet our decentralized application by clicking on the "Connect Wallet" button at the top right conner of the app in desktop view or in the menu on the mobile view.
                        </p>
                    </article>
                    <article>
                        <h3>Check Eligibility</h3>
                        <p>
                            Clicking on the "Check Eligibility" button on the main page of this app, allows the app to check your eligibility to claim the airdrop. <span className="highlight">(Check if you HODL {tokenName})</span> 
                        </p>
                    </article>
                    <article>
                        <h3>Claim Air Tokens</h3>
                        <p>
                            The "Air Tokens" act as a fail safe method designed to protect against "repeat request" and other form(s) of malicious attacks (possible) and protect the entire Airdrop Campaign.
                        </p>
                    </article>
                    <article>
                        <h3>Wait Period</h3>
                        <p>
                            After completing the "Claim Air Token" method, you can click "Convert AirToken" button at the top right conner of any page you currently are viewing. This step comes with a 24 hour(s) wait between both processes. (You will wait for 24 hours to convert your {airTokenName}(s) to {tokenName}(s))
                        </p>
                    </article>
                    <article>
                        <h3>Convert {airTokenName} to {tokenName} @ 1:1</h3>
                        <p>
                            After patiently waiting and HODLing, you can click on the "Convert AirToken" button again, provide the amount you would like to claim or click on the "MAX" button to provide total balance of Air Tokens.
                        </p>
                        <p>
                            Then click on the "Convert" button to convert {airTokenName}(s) to {tokenName}(s) @ 1:1.
                        </p>
                    </article>
                    <article>
                        <h3>Things to Note</h3>
                        <p>
                            You should be a HODLer for at least 24 hours to convert and this accounts for a complete 24 hours period from the date of Air Token Claim. 
                        </p>
                        <p>
                            The time of day when you perform the Air Token Claim can greatly affect the quantity of Air Tokens you receive.
                        </p>
                        <ul>
                            <li><span className="highlight">Morning</span> 7% - 10% of {tokenName}(s) balance at time of transaction.</li>
                            <li><span className="highlight">Afternoon</span> 5% - 7% of {tokenName}(s) balance at time of transaction.</li>
                            <li><span className="highlight">Evening</span> 3% - 5% of {tokenName}(s) balance at time of transaction.</li>
                            <li><span className="highlight">Night</span> 1% - 3% of {tokenName}(s) balance at time of transaction.</li>
                        </ul> 
                    </article>
                </div>
            </div>
        </div>
    )
}

export default Guide
