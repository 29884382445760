import React, { useEffect, useState } from 'react'
import { useStateValue } from '../context/StateProvider'
import { connectWallet, initContract } from '../utils'
import { ethers, formatUnits, parseUnits } from 'ethers'
import ContentLoader from './ContentLoader'
import { MdOutlineSwapCalls } from "react-icons/md";

const ConvertAirToken = () => {
    const [{wallet, contractAddress, abi, tokenAbi, contract, tokenName, airTokenName}, dispatch] = useStateValue()
    const [assetBalance, setAssetBalance] = useState(0)
    const [loadingAssetBalance, setLoadingAssetBalance] = useState(false)
    const [convertValue, setConvertValue] = useState('')
    const [flashNotification, setFlashNotification] = useState(null)
    const [cannotConvert, setCannotConvert] = useState(true)

    function getAirTokenBalance(address) {
        return new Promise(async(resolve, reject) => {
            try {
                const {signer} = await connectWallet()
                const contract = await initContract(contractAddress, abi, signer)
                const airTokenAddress = await contract.airTokenAddress()
                const airTokenContract = await initContract(airTokenAddress, tokenAbi, signer)
                const airTokenBalance = await airTokenContract.balanceOf(address)
                return resolve(formatUnits(airTokenBalance))
            } catch(err) {
                return reject(err)
            }
        })
    }
    async function convertACOQToCOQ() {
        if (parseFloat(convertValue) > parseFloat(assetBalance))
            return
        dispatch({
            type: 'TOGGLE_LOADER'
        })
        try {
            const {signer, address} = await connectWallet()
            let airDropContract
            if (contract === null)
                airDropContract = await initContract(contractAddress, abi, signer)
            else
                airDropContract = contract

            const airTokenAddress = await airDropContract.airTokenAddress()
            const airTokenContract = await initContract(airTokenAddress, tokenAbi, signer)
            const airTokenToAirDropContractAllowance = await airTokenContract.allowance(wallet, contractAddress)
            if (airTokenToAirDropContractAllowance < parseUnits(convertValue)) {
                const approveContractCall = await airTokenContract.approve(contractAddress, ethers.MaxUint256)
                await approveContractCall.wait()
            }
            const convertContractCall = await airDropContract.convertAirDrop(parseUnits(convertValue))
            await convertContractCall.wait()
            const message = `You have successfully converted ${parseFloat(convertValue).toLocaleString()} ${airTokenName}(s) to ${parseFloat(convertValue).toLocaleString()} ${tokenName}(s)`
            setAssetBalance(0)
            setConvertValue('')
            dispatch({
                type: 'SET_NOTIFICATION',
                data: {
                    type: 'success',
                    message
                }
            })

        } catch(err) {
            const message = err.reason?.message || err.reason || err.message || JSON.stringify(err)
            dispatch({
                type: 'SET_NOTIFICATION',
                data: {
                    type: 'error',
                    message
                }
            })             
        } finally {
            dispatch({
                type: 'TOGGLE_LOADER'
            })
        }
    }
    useEffect(() => {
        if (wallet && assetBalance <= 0) {
            async function makeInitialCheck() {
                setLoadingAssetBalance(true)
                dispatch({
                    type: 'TOGGLE_LOADER'
                }) 
                try {
                    const aBalance = await getAirTokenBalance(wallet)
                    setAssetBalance(aBalance)
                } catch(err) {
                    dispatch({
                        type: 'SET_NOTIFICATION',
                        data: {
                            type: 'error',
                            message: err.reason || err.message || JSON.stringify(err)
                        }
                    })
                } finally {
                    dispatch({
                        type: 'TOGGLE_LOADER'
                    }) 
                    setLoadingAssetBalance(false)
                }
            }
            makeInitialCheck()
        }
        if (assetBalance > 0)
            setAssetBalance(assetBalance)
    }, [wallet, abi, tokenAbi, contractAddress, dispatch, assetBalance])
    useEffect(() => {
        setFlashNotification('')
        if (!['', null].includes(convertValue)) {
            let valueCharArray = convertValue.split('')
            if (
                (
                    valueCharArray[valueCharArray.length - 1] === '.' 
                    &&
                    valueCharArray.slice(0, valueCharArray.length - 1).includes('.')
                ) 
                ||
                (
                    valueCharArray[valueCharArray.length - 1] !== '.'
                    &&
                    isNaN(valueCharArray[valueCharArray.length - 1])
                )
            ) {
                const correctValue = valueCharArray.slice(0, valueCharArray.length - 1).join('')
                if (parseFloat(correctValue) <= 0) {
                    setFlashNotification(<div className='flash-note warning'>Input a valid value to process convert action</div>)
                    setCannotConvert(false)
                }
                setConvertValue(correctValue)
            }else if (parseFloat(valueCharArray.join('')) > 0 && parseFloat(valueCharArray.join('')) <= parseFloat(assetBalance))
                setCannotConvert(false)
            else if (parseFloat(valueCharArray.join('')) > parseFloat(assetBalance)) {
                setFlashNotification(<div className='flash-note error'>Insufficient balance</div>)
                setCannotConvert(false)
            } else if (parseFloat(valueCharArray.join('')) <= 0) {
                setFlashNotification(<div className='flash-note warning'>Input a valid value to process convert action</div>)
                setCannotConvert(false)
            }
            else
                setCannotConvert(true)
        }
    }, [convertValue, assetBalance])
    return (
        <div className='content'>
            <div className="content-container small-centered-box shadow-bottom padded-box-20">
                <div className="text-container">
                    Convert <span className="highlight">{airTokenName}</span> to <span className="highlight">{tokenName}</span> @ 1:1
                </div>
                <div className="balance-container">
                    { loadingAssetBalance ? <ContentLoader /> : <div className='font'><span className="medium-text">{parseFloat(assetBalance).toLocaleString()}</span><span className=""> {airTokenName} (aCOQ)</span></div>}
                </div>
                <div className="input-container">
                    <input type="text" value={convertValue} onChange={(e) => setConvertValue(e.target.value)} />
                    <label htmlFor="max" onClick={() => setConvertValue(assetBalance || '0.0')} >MAX</label>
                </div>
                <div className="flash-notification">
                    {flashNotification !== null ? flashNotification : null}
                </div>
                <div className="btn-container">
                    <button
                        onClick={convertACOQToCOQ}
                        disabled={cannotConvert}
                        className={`btn ${cannotConvert ? 'disabled' : ''}`}>Convert <MdOutlineSwapCalls /></button>
                </div>
            </div>
        </div>
    )
}

export default ConvertAirToken
